import React from "react";
import { Col, Row } from "react-bootstrap";
import { CgCPlusPlus } from "react-icons/cg";
import {
  DiGithub,
  DiJavascript1,
  DiReact,
  DiLinux,
  DiNodejs,
  DiPython,
  DiBootstrap,
  DiMongodb,
  DiGit,
  DiJava,
  DiHtml5,
} from "react-icons/di";
import { SiFirebase, SiPostgresql } from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
        <p style={{ fontSize: 15 }}>Java</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
        <p style={{ fontSize: 15 }}>Node.js</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJavascript1 />
        <p style={{ fontSize: 15 }}>JavaScript</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
        <p style={{ fontSize: 15 }}>React</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiBootstrap />
        <p style={{ fontSize: 15 }}>Bootstrap</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiHtml5 />
        <p style={{ fontSize: 15 }}>HTML5</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <CgCPlusPlus />
        <p style={{ fontSize: 15 }}>C++</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
        <p style={{ fontSize: 15 }}>Python</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiLinux />
        <p style={{ fontSize: 15 }}>Linux</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
        <p style={{ fontSize: 15 }}>Mongodb</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
        <p style={{ fontSize: 15 }}>Git</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGithub />
        <p style={{ fontSize: 15 }}>Github</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
        <p style={{ fontSize: 15 }}>Firebase</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <p style={{ fontSize: 15 }}>PostgreSQL</p>
      </Col>{" "}
    </Row>
  );
}

export default Techstack;
