import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Hi Everyone, I am <span className="purple">Amit Halfon </span>
            <br /> I have a bachelor's degree in computer science (B.Sc),
            <br />
            Additionally, I am presently working as a freelance full-stack
            developer.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
            <li className="about-activity">
              <ImPointRight /> work out
            </li>
          </ul>
          <br />
          <a
            href="mailto:amitha51111@gmail.com"
            style={{
              color: "white",
              fontWeight: 500, // Adjust the numeric value accordingly
              fontStyle: "italic",
              marginRight: "2px",
            }}
          >
            Contact me
          </a>
          {/*
          <p style={{ color: "rgb(155 126 172)" }}>
            "Strive to build things that make a difference!"{" "}
          </p>
          <footer className="blockquote-footer">name </footer> */}
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
