import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "../Projects/ProjectCards";
import Particle from "../Particle";
import noaWeb from "../../Assets/Projects/noatalelWeb.png";

function BlogProjects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My <strong className="purple">Blog </strong>
        </h1>
        <p style={{ color: "white" }}>Here are some articles.</p>
        <Row>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={noaWeb}
              isBlog={false}
              title="Cosmetic Site"
              description="Online code and markdown editor build with react.js. Online Editor which supports html, css, and js code with instant view of website. Online markdown editor for building README file which supports GFM, Custom Html tags with toolbar and instant preview.Both the editor supports auto save of work using Local Storage"
              ghLink="https://noatalel.onrender.com/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default BlogProjects;
