import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiVisualstudiocode,
  SiPostman,
  SiSlack,
  SiVercel,
  SiMacos,
  SiUbuntu,
} from "react-icons/si";
import { DiAws, DiDocker } from "react-icons/di";
function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      {/* <Col xs={4} md={2} className="tech-icons">
        <SiMacos />
        <p style={{ fontSize: 15 }}>Macos</p>
      </Col> */}
      <Col xs={4} md={2} className="tech-icons">
        <SiVisualstudiocode />
        <p style={{ fontSize: 15 }}>Visual studio code</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiDocker />
        <p style={{ fontSize: 15 }}>Docker</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostman />
        <p style={{ fontSize: 15 }}>Postman</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiAws />
        <p style={{ fontSize: 15 }}>AWS</p>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiUbuntu />
        <p style={{ fontSize: 15 }}>Ubuntu</p>
      </Col>
    </Row>
  );
}

export default Toolstack;
